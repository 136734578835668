import styled, { css } from "styled-components"
import device from "../../theme/devices"
export const Container = styled.div``
export const Blur = styled.div<{ isBlurred: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 250ms ease-out;
  backdrop-filter: blur(0);
  ${props =>
    props.isBlurred &&
    css`
      transition: all 550ms ease-out;
      backdrop-filter: blur(10px);
    `}
  background: rgba(200,200,200,0.2);
`
export const Image = styled.div`
  height: 100vh;
  @media (${device.phone}) {
    display: none;
  }
`
export const Video = styled.div<{ readyState: string; visible: boolean }>`
  height: 100vh;
  @media (${device.phone}) {
    display: block;
  }
  ${props =>
    props.readyState === "ready"
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}
  ${props =>
    props.visible
      ? css`
      zIndex: : -1;
        `
      : css`
          zindex: undefined;
        `}
  position: "fixed";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
