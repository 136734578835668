import { StaticImage } from "gatsby-plugin-image"
import React, {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import { useLocation, useParams } from "react-router-dom"
import { EventContext } from "../../context/EventContext"
import * as S from "./styled"
const videos = ["Intro", "Side_1", "Side_2", "Side_3", "Side_4"] as const

const Video: React.FC<{
  src: string
  state: "playing" | "at start" | "at end"
  visible?: boolean
  onEnd?: () => void
  aboutToEndPercentagePlayed?: number
  onAboutToEnd?: () => void
}> = ({
  src,
  state,
  visible,
  onEnd,
  aboutToEndPercentagePlayed = 0.7,
  onAboutToEnd,
}) => {
  const ref = useRef<HTMLVideoElement>() as MutableRefObject<HTMLVideoElement>
  const onTimeUpdate = useCallback(() => {
    const videoElement = ref.current
    if (!videoElement) return
    if (
      videoElement.currentTime / videoElement.duration >=
        aboutToEndPercentagePlayed &&
      state === "playing"
    ) {
      onAboutToEnd?.()
    }
  }, [onAboutToEnd, state])

  const [readyState, setReady] = useState<null | "ready" | "initializing">(
    process.env.GATSBY_FOR_ANDROID ? null : "ready"
  )

  useEffect(() => {
    if (!ref.current || readyState) return
    ref.current.onpause = e => {
      console.trace("Video paused")
      console.log(e)
    }
    setReady("initializing")
    console.log("Setting video ready", src)
    ref.current.muted = true
    ref.current.autoplay = true
    ref.current
      .play()
      .then(() => {
        console.log("Play succeeded for ", src)
        ref.current.currentTime = 0
        ref.current.pause()
      })
      .then(() => setReady("ready"))
      .catch(e => {
        console.log("Play failed for", src)
        console.error(e)
      })
      .finally(() => (ref.current.autoplay = false))
  }, [readyState, src])

  useEffect(() => {
    if (!ref.current || readyState !== "ready") return

    const video = ref.current
    if (!visible || state === "at start") {
      setTimeout(() => (video.currentTime = 0))
    } else if (state === "at end") {
      video.currentTime = video.duration
    }

    if (visible && state === "playing") {
      video.play()
    }
  }, [visible, state, readyState])
  return (
    <>
      <div
        style={{
          opacity: readyState === "ready" ? 1 : 0,
          zIndex: !visible ? -1 : undefined,
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
        className={"bg-video0"}
      >
        <video
          ref={ref}
          src={
            sessionStorage.getItem(
              `/${process.env.GATSBY_FOR_ANDROID ? "assets/" : ""}${src}.mp4`
            ) || `/${process.env.GATSBY_FOR_ANDROID ? "assets/" : ""}${src}.mp4`
          }
          muted
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
            objectPosition: "center",
          }}
          onTimeUpdate={onTimeUpdate}
          onEnded={onEnd}
          preload={"auto"}
          playsInline
        />
      </div>
    </>
  )
}

export const EventBackground: React.FC = ({ children }) => {
  const { booth } = useParams<{ booth: string; product: string }>()
  const eventData = useContext(EventContext).data
  const location = useLocation()

  const [[currentVideo, playing], setCurrentVideo] = useState<
    [typeof videos[number], "playing" | "at start" | "at end"]
  >(["Intro", "at start"])
  const [isBlurred, setBlurred] = useState<boolean>(true)
  const blur = useCallback(() => setBlurred(true), [setBlurred])

  const lastVideo = useRef<number>(-1) as MutableRefObject<number>
  const lastBooth = useRef<string>()

  useLayoutEffect(() => {
    if (location.pathname.includes("checkout")) return
    if (!eventData) return
    if (lastBooth.current === booth) return

    if (!lastBooth.current && lastVideo.current === -1) {
      // Intro - play intro video
      setBlurred(false)
      lastBooth.current = booth
      setCurrentVideo(["Intro", "playing"])
      return
    }

    if (booth) {
      setBlurred(false)
      lastBooth.current = booth
      lastVideo.current = (lastVideo.current + 1) % 4
      // @ts-ignore
      setCurrentVideo([`Side_${lastVideo.current + 1}`, "playing"])
      return
    }
    setBlurred(true)
    lastBooth.current = undefined
    setCurrentVideo(["Intro", "at start"])
    lastVideo.current = -1
  }, [booth, eventData, location.pathname])

  const onEnd = useCallback(() => {
    setCurrentVideo(curr => [curr[0], "at end"])
  }, [])

  const onIntroEnd = useCallback(() => {
    onEnd()
  }, [onEnd])

  const [loadedVideos, setLoadedVideos] = useState(
    process.env.GATSBY_FOR_ANDROID ? videos.slice(0, 2) : videos
  )

  useEffect(() => {
    if (!process.env.GATSBY_FOR_ANDROID) {
      return
    }
    setLoadedVideos(() => {
      switch (currentVideo) {
        case "Intro":
          return ["Intro", "Side_1"]
        case "Side_1":
          return ["Side_1", "Side_2"]
        case "Side_2":
          return ["Side_2", "Side_3"]
        case "Side_3":
          return ["Side_3", "Side_4"]
        case "Side_4":
          return ["Side_4", "Side_1"]
      }
    })
  }, [currentVideo])

  console.log("Loading videos", loadedVideos)

  return (
    <>
      {loadedVideos.map(vid => (
        <Video
          key={vid}
          src={vid}
          state={currentVideo === vid ? playing : "at start"}
          visible={currentVideo === vid}
          onEnd={vid === "Intro" ? onIntroEnd : onEnd}
          aboutToEndPercentagePlayed={vid === "Intro" ? 0.7 : 0.5}
          onAboutToEnd={blur}
        />
      ))}
      <StaticImage
        src="../../images/bg_replacement.png"
        alt={""}
        style={{
          width: "100%",
          height: "100%",
          opacity:  1,
          zIndex:  -1,
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
        objectFit={"fill"}
        placeholder={"none"}
        quality={1}
        className={"blur100 fallback-img0"}
        imgStyle={{ filter: "blur(8px)" }}
      />
      <S.Blur isBlurred={isBlurred} />
      {children}
    </>
  )
}
