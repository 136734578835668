import React, { useContext, useEffect } from "react"
import { EventContext } from "../../context/EventContext"
import styled from "styled-components"
import { NavLink } from "react-router-dom"
import { useEventNavigation } from "../../context/EventNavigationContext"
import device from "../../theme/devices"
import { useMobileMenuContext } from "../../context/MobileMenuContext"
import { animated, useTrail } from "react-spring"
import { useTranslation } from "react-i18next"

const locales = [
  { name: "English", code: "en" },
  { name: "Japanese", code: "ja" },
]

export const ContentContainer = styled.div`
  padding: 56px 20px 16px 20px;
  flex: 1;

  display: flex;
  flex-direction: column;

  a {
    color: inherit;
    text-decoration-color: transparent;
    transition: text-decoration-color 250ms ease-out;
    :hover {
      text-decoration: underline;
    }

    &.active {
      text-decoration-color: currentColor;
    }
  }

  @media (${device.desktop}) {
    padding-top: 30px;
  }
`

const Intro = styled.a`
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  margin-top: 50px;
  margin-bottom: 20px;
  transition: all 250ms ease-out;
  &.active {
    opacity: 0;
  }
`

const OL = styled.ol`
  list-style-type: none;
  counter-reset: li;
  padding: 0;
  margin: 0;

  > li {
    font-weight: 300;
    display: flex;
    font-size: 19px;
    line-height: 26px;
    white-space: pre-wrap;

    a {
      margin-top: auto;
      margin-bottom: 8px;
    }

    :before {
      counter-increment: li;
      content: counter(li, decimal-leading-zero) " ";
      font-weight: 500;
      font-size: 19px;
      line-height: 26px;
      min-width: 35px;
    }

    &:not(:last-child) {
      margin-bottom: 26px;
    }
  }
`

const Footer = styled.div`
  margin-top: auto;
  font-size: 12px;
  font-style: normal;
  line-height: 12px;
  letter-spacing: 0;
  font-weight: 400;
  padding-top: 40px;
  color: rgba(255, 255, 255, 0.6);

  select {
    background: transparent;
    color: inherit;
    border: 0;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    padding: 0 !important;
    font-weight: bold;
    font-family: inherit;
    font-weight: 500;
    color: #fff;
    padding-right: 16px !important;
    position: relative;
    z-index: 2;

    & + span {
      display: block;
      width: 12px;
      height: 8px;
      position: absolute;
      right: 0;
      transform: rotate(180deg);
      background: url('data:image/svg+xml;charset=UTF-8,<svg  width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m1 7.4 5-5 5 5 1-1-6-6-6 6 1 1Z" fill="white" /></svg>');
    }
  }

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;

    > div {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
    }
  }

  span {
    display: block;
    font-weight: 500;
    color: white;
  }
`

export const LanguageSelect = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  position: relative;
`

export const Copyright = styled.div`
  margin-top: 20px;

  color: white;
  @media (${device.tablet}) {
    margin-top: 42px;
  }
`
import { navigate } from "gatsby"

export const EventMenu: React.FC<
  | { withSlideIn: true; startSlideIn: boolean; onRest: () => any }
  | { withSlideIn?: false; startSlideIn?: boolean; onRest?: () => any }
> = props => {
  const eventContext = useContext(EventContext)
  const navigationUtil = useEventNavigation()
  const mobileMenuContext = useMobileMenuContext()
  const { i18n, t } = useTranslation()
  const showLanguageSelector = eventContext?.data?.showLanguageSelector

  const handleLanguageChange = event => {
    const selectedLanguage = event.target.value as "en" | "de"
    const currentPath = window.location.pathname
    const pathWithoutLocale = currentPath.replace(`/${i18n.language}`, "")

    // Build the new path with the selected language
    const newPath = `/${selectedLanguage}${pathWithoutLocale}`

    // Navigate to the new path
    eventContext.setEvent(event => ({
      ...event,
      status: "requested",
    }))
    navigate(newPath)
  }

  const [trail, trailRef] = useTrail(
    eventContext?.data?.booths.length || 0,
    () => ({
      immediate: !props.withSlideIn,
      onRest: props.onRest,
      from: {
        transform: "translateX(-100%)",
      },
      to: {
        transform: "translateX(0%)",
      },
    })
  )
  useEffect(() => {
    if (!props.withSlideIn) {
    } else if (props.startSlideIn) trailRef.resume()
    else trailRef.pause()
  }, [props.withSlideIn, props.startSlideIn])

  return (
    <ContentContainer>
      <Intro
        exact
        as={NavLink}
        activeClassName={"active"}
        to={navigationUtil.getBoothUrl("")}
      >
        {t("intro")}
      </Intro>
      <OL>
        {trail.map((trail, i) => (
          <animated.li key={i} style={trail} onClick={mobileMenuContext.close}>
            <NavLink
              activeClassName={"active"}
              to={navigationUtil.getBoothUrl(eventContext.data!.booths[i].slug)}
            >
              {eventContext.data!.booths[i].name}
            </NavLink>
          </animated.li>
        ))}
      </OL>
      <Footer>
        <div>
          {eventContext.data?.host && (
            <div>
              <div>{t("exhibitionHost")}:</div>{" "}
              <span>{eventContext.data.host}</span>
            </div>
          )}
          {showLanguageSelector && (
            <div>
              <div>{t("language")}</div>
              <LanguageSelect>
                <select
                  id="lang-selector"
                  onChange={handleLanguageChange}
                  value={i18n.language}
                >
                  {locales.map(locale => (
                    <option
                      key={locale.code}
                      value={locale.code}
                      disabled={locale.code === i18n.language}
                    >
                      {locale.name}
                    </option>
                  ))}
                </select>
                <span />
              </LanguageSelect>
            </div>
          )}
        </div>
        <Copyright>{t("copyrightText")}</Copyright>
      </Footer>
    </ContentContainer>
  )
}
