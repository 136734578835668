import styled, { css } from "styled-components"

export const TranslationNoticeWrapper = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-columns: 0px repeat(12, 1fr) 0px;
  gap: 20px;
  z-index: 99;
`
export const TranslationNoticeContainer = styled.div<{
  $show: boolean
  $collapsed: boolean
  $hide: boolean
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  grid-column: 5 / 15;
  margin-left: -20px;
  background: #1f1213;
  opacity: 0.88;
  backdrop-filter: blur(20px);
  color: #fff;
  padding: 12px 20px;
  font-size: 12px;
  opacity: 0;
  transform: translateY(80px);
  transition: all 0.6s ease-in;

  ${({ $show }) =>
    $show &&
    css`
      opacity: 0.88;
      transform: translateY(0);
    `}

  ${({ $hide }) =>
    $hide &&
    css`
      opacity: 0;
      transform: translateY(80px);
    `}

  ${({ $collapsed }) =>
    $collapsed &&
    css`
      grid-column: 3 / 15;
    `}

  p {
    margin: 0 auto;
  }
`

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
`
