import React, { useEffect, useState } from "react"

import {
  TranslationNoticeContainer,
  TranslationNoticeWrapper,
  CloseButton,
} from "./styled"
import { ReactComponent as CloseIcon } from "../../icons/ic-close.svg"
import { useSidebarContext } from "../../context/EventSidebarContext"
import { useTranslation } from "react-i18next"

export const TranslationNotice = () => {
  const { t } = useTranslation()
  const { slidedIn, collapsed } = useSidebarContext()
  const [isVisible, setIsVisible] = useState(() => {
    return sessionStorage.getItem("translationNoticeClosed") !== "true"
  })
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (slidedIn === "yes") {
      setShow(true)
    } else if (slidedIn === "no") {
      setShow(false)
    }
  }, [slidedIn])

  const handleClose = () => {
    setIsVisible(false)
    sessionStorage.setItem("translationNoticeClosed", "true")
  }

  if (!isVisible) return null

  return (
    <TranslationNoticeWrapper>
      <TranslationNoticeContainer
        $show={show}
        $hide={!show}
        $collapsed={collapsed}
      >
        <p>{t("translationNotice")}</p>
        <CloseButton type="button" onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
      </TranslationNoticeContainer>
    </TranslationNoticeWrapper>
  )
}
