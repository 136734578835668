/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import { setI18n } from 'gatsby-plugin-react-i18next';

export const onClientEntry = async () => {
  console.log("On client entry")
  if (typeof IntersectionObserver === `undefined`) {
    console.log("adding intersection-observer polyfill")
    await import(`intersection-observer`)
  }

  if (!("scrollBehavior" in document.documentElement.style)) {
    console.log("adding smooth-scroll polyfill")
    await import("smoothscroll-polyfill").then(p => p.polyfill())
  }

  const language = typeof window !== 'undefined' ? localStorage.getItem('language') : null;

  if (language) {
    setI18n(language); // Set the language using the `gatsby-plugin-react-i18next` API
  }
}


export const onRouteUpdate = ({ location }) => {
  // Persist language in localStorage when the page is loaded
  if (typeof window !== 'undefined') {
    const language = localStorage.getItem('language') || 'en'; // Default to English if no language set
    setI18n(language); // Set the language using the `gatsby-plugin-react-i18next` API
  }
};
