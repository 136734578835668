import React, { useContext, useEffect, useState } from "react"
import { useStaticDataWatcher } from "../hooks/useStaticDataWatcher"
import { useTranslation } from "react-i18next"
import { Translation } from "../types/Translation"
import { EventContext } from "./EventContext"

export const AppContext = React.createContext<{
  translations: Translation | {}
  setTranslations: React.Dispatch<React.SetStateAction<Translation | {}>>
}>({
  translations: {},
  setTranslations: () => {},
})

const Watcher = () => {
  useStaticDataWatcher()
  return null
}

export const WithAppContext: React.FC = ({ children }) => {
  const [translations, setTranslations] = useState({})
  const [staticDataLoaded, setStaticDataLoaded] = useState(false)
  const eventContext = useContext(EventContext)

  const { i18n } = useTranslation()

  i18n.removeResourceBundle(i18n.language, "translation")
  i18n.addResources(i18n.language, "translation", translations)

  useEffect(() => {
    // @ts-expect-error
    if (translations.__typename) {
      eventContext.setEvent(event => ({ ...event, lang: i18n.language }))
      setTimeout(() => setStaticDataLoaded(true), 100)
    }
  }, [i18n.language, translations])

  return (
    <AppContext.Provider value={{ translations, setTranslations }}>
      <Watcher />
      {staticDataLoaded && children}
    </AppContext.Provider>
  )
}
