import React, { createContext } from "react"
import type { IndexEntry } from "../../../electron/eventCache"

const OfflineAppContext = createContext<{
  events: IndexEntry[]
  status: OfflineContextStatus
}>({
  events: [],
  status: {
    type: "loading",
  },
})

export type OfflineContextStatus =
  | {
      type: "loading"
    }
  | {
      type: "done" | "downloading" | "check redownload or open" | "opening"
      event?: IndexEntry
    }

export const WithOfflineAppContext: React.FC<{
  events: IndexEntry[]
  status: OfflineContextStatus
}> = ({ events, children }) => {
  return (
    <OfflineAppContext.Provider value={{ events, status: { type: "loading" } }}>
      {children}
    </OfflineAppContext.Provider>
  )
}
