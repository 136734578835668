import React from "react"
import { Helmet } from "react-helmet"
import { StyleSheetManager, ThemeProvider } from "styled-components"
import griddie from "styled-griddie"
import { Normalize } from "styled-normalize"
import { GlobalStyles } from "./globalStyles"
import { PageProps } from "gatsby"
import { Fade } from "./fadeTransition"
import { WithEventContext } from "../../context/EventContext"
import { lightTheme } from "../../theme/light"
import PageWrapperForElectron from "../PageWrapperForElectron"
import { WithAppContext } from "../../context/AppContext"

const Wrapper = process.env.GATSBY_FOR_ELECTRON
  ? PageWrapperForElectron
  : React.Fragment

export const PageWrapper: React.FC<PageProps> = props => (
  <StyleSheetManager stylisPlugins={[griddie]}>
    <>
      <Helmet>
        <link rel="stylesheet" href="/fonts/graphik.css" />
      </Helmet>

      <Normalize />
      <ThemeProvider theme={lightTheme}>
        <GlobalStyles />
        <WithAppContext>
          <WithEventContext>
            {/*@ts-ignore*/}
            <Fade location={props.location}>
              <Wrapper>{props.children}</Wrapper>
            </Fade>
          </WithEventContext>
        </WithAppContext>
      </ThemeProvider>
    </>
  </StyleSheetManager>
)

export default PageWrapper
