import { useContext, useEffect } from "react"
import { fetch } from "cross-fetch"
import { AppContext } from "../context/AppContext"
import { Translation } from "../types/Translation"
import { useTranslation } from "react-i18next"

const getStaticData = (locale?: string) => {
  return fetch(
    `${
      process.env.GATSBY_FOR_ELECTRON ? "vtrapi:/" : ""
    }/.netlify/functions/get-static-data`,
    {
      method: "POST",
      body: JSON.stringify({
        locale,
      }),
    }
  )
    .then(res => res.json())
    .then(d => {
      if (d.error) {
        throw d.error
      }
      return d as Translation
    })
    .then(e => {
      return e
    })
}

export const useStaticDataWatcher = () => {
  const appContext = useContext(AppContext)

  const { i18n } = useTranslation()

  useEffect(() => {
    getStaticData(i18n.language)
      .then(data => {
        appContext.setTranslations(data)
      })
      .catch(error => {
        console.log({ error })
        appContext.setTranslations({})
      })
  }, [i18n.language])
}
