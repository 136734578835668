import React, { useState } from "react"
import { Event } from "../types/Event"
import { useEventWatcher } from "../hooks/useEventWatcher"
import { useTranslation } from "react-i18next"

type EventFetchStatus =
  | "success"
  | "fetching"
  | "failed"
  | "requested"
  | "reset"
  | undefined

export const EventContext = React.createContext<{
  slug?: string
  code?: string
  confidentialAccessCode?: string
  data?: Event
  status?: EventFetchStatus
  lang: string
  setEvent: React.Dispatch<
    React.SetStateAction<{
      code?: string
      slug: string
      confidentialAccessCode?: string
      data?: Event
      status?: EventFetchStatus
      lang: string
    }>
  >
}>({
  slug: "",
  code: "",
  lang: "",
  setEvent: () => {},
})

const Watcher = () => {
  useEventWatcher()
  return null
}

export const WithEventContext: React.FC<{}> = ({ children }) => {
  const { i18n } = useTranslation()
  const [{ slug, code, confidentialAccessCode, data, status, lang }, setEvent] =
    useState<{
      slug: string
      code?: string
      data?: Event
      status?: EventFetchStatus
      confidentialAccessCode?: string
      lang: string
    }>({
      slug: "",
      code: "",
      confidentialAccessCode: undefined,
      lang: i18n.language || "en",
    })

  return (
    <EventContext.Provider
      value={{
        slug,
        code,
        confidentialAccessCode,
        setEvent,
        data,
        status,
        lang,
      }}
    >
      <Watcher />
      {children}
    </EventContext.Provider>
  )
}
