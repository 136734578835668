import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { IndexEntry } from "../../../electron/eventCache"
import { Event } from "../../types/Event"
import {
  OfflineContextStatus,
  WithOfflineAppContext,
} from "./OfflineAppContext"
import "react-toastify/dist/ReactToastify.css"
import { toast, ToastContainer } from "react-toastify"
//@ts-ignore
import checkIconUrl from "../../icons/ic-check.svg"
import { ToastContainerStyles } from "../EventLayout"
import { lightTheme } from "../../theme/light"

const useElectronObserver: () => [
  events: IndexEntry[],
  state: OfflineContextStatus
] = () => {
  const [events, setEvents] = useState<IndexEntry[]>([])
  const [state, setState] = useState<OfflineContextStatus>({ type: "loading" })

  useEffect(() => {
    // Get events on first load
    setState({ type: "loading" })
    setEvents(window.api.send("getAvailableEvents"))
    setState({ type: "done" })
  }, [])

  useEffect(() => {
    console.log("Setting up receivers")
    window.api.receive("downloading", (args: any) => {
      console.log("Got downloading", args)
      navigate("/downloading")
    })
    window.api.receive("downloadDone", (args: Event) => {
      navigate("/")
      toast(
        <>
          <img src={checkIconUrl} style={{ marginRight: 8 }} />
          {`${args.name} ${
            args.hasConfidentialAttachments
              ? "(with confidential attachments)"
              : ""
          } was successfully downloaded.`}
        </>
      )
      setState({ type: "loading" })
      setEvents(window.api.send("getAvailableEvents"))
      setState({ type: "done" })
    })
  }, [])

  return [events, state]
}

const PageWrapperForElectron: React.FC = props => {
  const [events, state] = useElectronObserver()
  return (
    <WithOfflineAppContext events={events} status={state}>
      <ToastContainer position={"top-right"} />
      <ToastContainerStyles
        sidebarCollapsed={true}
        theme={lightTheme}
        dontOveridePosition
      />
      {props.children}
    </WithOfflineAppContext>
  )
}

export default PageWrapperForElectron
